import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './dodectrans.png';
import './App.css';

const App = () => {
  const [articles, setArticles] = useState([]);
  const [activeSummaries, setActiveSummaries] = useState({});
  const [activeTitles, setActiveTitles] = useState({});
  const [darkMode, setDarkMode] = useState(true); // Set default to dark mode
  const [selectedAgency, setSelectedAgency] = useState('All');
  const [isLoading, setIsLoading] = useState(false);

  const summaryOrder = ['Medium_GPT', 'Bullet_GPT', 'Short_GPT'];
  const titleOrder = ['Title_GPT', 'Article Title'];

  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);

  useEffect(() => {
    fetchArticles();
  }, [selectedAgency]);

  const fetchArticles = async () => {
      setIsLoading(true); // set loading to true before fetching articles
      try {
        const response = await axios.get('https://api.briefer.news/articles');
        let sortedArticles = response.data.sort((a, b) => {
          const dateA = new Date(a['Publish Date']);
          const dateB = new Date(b['Publish Date']);
          return dateB - dateA;
        });

        // filter out articles where nonpublish field is true
        sortedArticles = sortedArticles.filter(article => !article.nonpublish && article.isReadyForSite);

        const initialActiveTitles = {};
        const initialActiveSummaries = {};
        sortedArticles.forEach(article => {
          initialActiveTitles[article.ID] = getFirstAvailableTitle(article);
          initialActiveSummaries[article.ID] = getFirstAvailableSummary(article);
        });
        setActiveTitles(initialActiveTitles);
        setActiveSummaries(initialActiveSummaries);

        setArticles(sortedArticles.filter(article => selectedAgency === 'All' ? article.isReadyForSite : article['News Agency'] === selectedAgency));
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
      setIsLoading(false); // set loading to false after fetching articles
    };


  const getFirstAvailableTitle = (article) => {
    if (article['Title_GPT']) return 'Title_GPT';
    if (article['Article Title']) return 'Article Title';
    return titleOrder[0];
  };

  const getFirstAvailableSummary = (article) => {
    for (const summary of summaryOrder) {
      if (article[summary]) return summary;
    }
    return summaryOrder[0];
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const switchSummary = (id) => {
    const currentSummary = activeSummaries[id];
    const currentIndex = summaryOrder.indexOf(currentSummary);
    const nextIndex = (currentIndex + 1) % summaryOrder.length;
    setActiveSummaries({
      ...activeSummaries,
      [id]: summaryOrder[nextIndex],
    });
  };

  const switchTitle = (id) => {
    const currentTitle = activeTitles[id];
    const currentIndex = titleOrder.indexOf(currentTitle);
    const nextIndex = (currentIndex + 1) % titleOrder.length;
    setActiveTitles({
      ...activeTitles,
      [id]: titleOrder[nextIndex],
    });
  };

  const formatSummary = (summary, summaryType) => {
    if (summaryType !== 'Bullet_GPT') {
      return summary;
    }

    return summary.split('\\n- ').join('\n- ');
  };

  const handleAgencySelection = (agency) => {
    setSelectedAgency(agency);
  };

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <div className="header">
        <img src={logo} alt="Briefer News Icon" className={`icon ${darkMode ? 'dark-mode' : ''}`} />
        <h1>Briefer News</h1>
        <button onClick={() => setDarkMode(!darkMode)} className="dark-mode-button">
          {darkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>
      <div className="agency-buttons">
        <button
          className={`agency-button ${selectedAgency === 'All' ? 'active' : ''} all-button`}
          onClick={() => handleAgencySelection('All')}
        >
          <img
            src="/all.png"
            alt="All"
            className={`agency-icon ${selectedAgency === 'All' ? 'active' : ''}`}
          />
        </button>

        <img
          src="/nyt.png"
          alt="New York Times"
          className={`agency-icon ${selectedAgency === 'New York Times' ? 'active' : ''}`}
          onClick={() => handleAgencySelection('New York Times')}
        />
        <img
          src="/intercept.jpg"
          alt="The Intercept"
          className={`agency-icon ${selectedAgency === 'The Intercept' ? 'active' : ''}`}
          onClick={() => handleAgencySelection('The Intercept')}
        />
      </div>
      <ul className="articles">
        {isLoading ? (
          <li>Loading...</li>
        ) : articles.length > 0 ? (
          articles.map((article) => (
            <li key={article.ID}>
              {article['Image URL'] && <img src={article['Image URL']} alt="Article Image" className="article-image" />}
              <h2 onClick={() => switchTitle(article.ID)}>{article[activeTitles[article.ID] || 'Article Title']}</h2>
              {article['News Agency'] && <p className="news-agency">{article['News Agency']}</p>}
              {article['Article Authors'][0] !== 'Unknown' && <p>By: {article['Article Authors'].join(', ')}</p>}
              {article['Publish Date'] && <p>{formatDate(article['Publish Date'])}</p>}
              {article[activeSummaries[article.ID] || 'Short_GPT'] && (
                activeSummaries[article.ID] === 'Bullet_GPT' ? (
                  <p className="bullet-summary" onClick={() => switchSummary(article.ID)}>
                    {formatSummary(article[activeSummaries[article.ID] || 'Short_GPT'], activeSummaries[article.ID])}
                  </p>
                ) : (
                  <p onClick={() => switchSummary(article.ID)}>
                    {article[activeSummaries[article.ID] || 'Medium_GPT']}
                  </p>
                )
              )}
              {article['Article URL'] && (
                <a href={article['Article URL']} target="_blank" rel="noopener noreferrer">
                  Source
                </a>
              )}
            </li>
          ))
        ) : (
          <li>No articles available for the selected agency.</li>
        )}
      </ul>
    </div>
  );
};

export default App;
